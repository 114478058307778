var payment = new PaymentJs();

function PaymentInit() {
  payment.init(
    '5bPWMvt0ncGVvEF3OlS6',
    'number_div',
    'cvv_div',
    function (payment) {
      console.log(payment);
      payment.setNumberStyle({
        border: '0px solid transparent',
        width: '100%',
        'font-weight': '500',
        'font-size': '14px',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        padding: '8px 8px',
        color: '#7e8299',
        height: '100%',
      });
      payment.setNumberPlaceholder('1234 1234 1234 1234');

      payment.setCvvStyle({
        border: '1px solid #e4e6ef',
        width: '100%',
        'font-weight': '500',
        'font-size': '14px',
        'border-bottom-right-radius': '4px',
        padding: '8px 8px',
        color: '#7e8299',
        height: '100%',
      });

      payment.setCvvPlaceholder('CVC');

      payment.numberOn('focus', function (data) {
        payment.setNumberStyle({
          border: '0px solid transparent',
          outline: '0px solid transparent',
          width: '100%',
          'font-weight': '500',
          'font-size': '14px',
          'border-top-right-radius': '4px',
          'border-top-left-radius': '4px',
          padding: '8px 8px',
          color: '#7e8299',
          height: '100%',
        });
      });

      payment.numberOn('blur', function (data) {
        console.log(data);
        document.querySelector('.cardList').dataset.card = data.cardType;
      });

      payment.cvvOn('blur', function (data) {
        console.log(data);
      });
    }
  );
}

function interceptSubmit() {
  var data = {
    // first_name: $("#first_name").val(),
    // last_name: $("#last_name").val(),
    card_holder: document.querySelector('#card_holder').value,
    month: document.querySelector('#exp_month').value,
    year: document.querySelector('#exp_year').value,
    email: document.querySelector('#email').value,
  };
  const tokenProm = new Promise((resolve, reject) => {
    payment.tokenize(
      data, //additional data, MUST include card_holder (or first_name & last_name), month and year
      function (token, cardData) {
        //success callback function
        console.log(token, cardData);
        resolve(token);

        // document.querySelector('#transaction_token').value = token; //store the transaction token
        // $("#payment_form").get(0).submit(); //submit the form
      },
      function (errors) {
        console.log(errors);
        //error callback function
        reject(errors);

        //render error information here
      }
    );
  });

  return tokenProm;
}
